import request from '@/request/request';
import { Button, Card, Result, Spin, Typography } from '@arco-design/web-react';
import { IconFaceFrownFill } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
function Oauth() {
  const signRef = useRef('');
  const history = useHistory();

  const login = async () => {
    setLoading(true);
    try {
      const response = await request('/apiService/mechanism/login/oauth', {
        data: {
          sign: signRef.current,
        },
        method: 'POST',
      });
      // 记录登录状态
      localStorage.setItem('token', response.data.token);
      window.location.href = '/';
    } catch (e) {
      console.warn(e.message);
      setIsSuccess(false);
      setErrMsg(e.message || '登录失败，请稍后重试');
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [errMsg, setErrMsg] = useState('登录链接已失效，请重新发起登录');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log('sign', params.get('sign'));
    signRef.current = params.get('sign');
    if (!signRef.current) {
      window.location.href = '/';
    } else {
      login();
    }
  }, [window.location.search]);

  return (
    <div
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: 'var(--color-fill-2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <Card
          bodyStyle={{
            width: 400,
            minHeight: 300,
            padding: '50px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin dot tip="请稍候，正在授权登录.." />
        </Card>
      ) : (
        <Card
          bodyStyle={{
            width: 400,
            minHeight: 300,
            padding: '50px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isSuccess && (
            <Result
              status="error"
              title="本次登录授权失败"
              icon={<IconFaceFrownFill />}
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  返回首页
                </Button>
              }
            >
              <Typography
                className="result-content"
                style={{ background: 'var(--color-fill-2)', padding: 24 }}
              >
                <Typography.Paragraph>错误信息</Typography.Paragraph>
                <ul>
                  <li> {errMsg} </li>
                </ul>
              </Typography>
            </Result>
          )}
        </Card>
      )}
    </div>
  );
}

export default Oauth;
